import "./betslip.css";
import {
    addAllToBetSlip,
    addToTJKBetSlip,
    betSlipActionSubject, clearTjkBetSlip, getBetCount, getLegsSelections,
    isAdded, isAddedAll, mobileBetSlipActions, mobileBetSlipSubject, PlayTjkCoupon, removeFromBetSlip,
    setTjkBetSlipMaxAmount, setTjkBetSlipMultiply, setTjkShareCoupon, setTjkUsePointBalance, tjkBetSlipOptions,
    tjkBetslipStore, tjkCompleteBettingTypes, tjkSortedBetTypes, updateBetCount
} from "../../services/betslip.service";
import React, {useEffect} from "react";
import {
    CurrencyFormat,
    dateTimeStringFormat, preventBetSlipValues,
    preventNegativeValues,
    useForceUpdate
} from "../../services/misc.functions";
import iconTrash from "../../assets/feather/trash-2.svg";
import iconSave from "../../assets/feather/save.svg";
import iconSlash from "../../assets/feather/slash.svg";
import iconLeft from "../../assets/feather/chevron-left.svg";
import {SortMyHorse} from "./sortmyhorse";
import _ from "lodash";
import {auth} from "../../store/auth.store";
import {bulletinProvider} from "../../services/bulletin.service";
import {modalServiceSubject} from "../../services/modal.service";
import {useNavigate} from "react-router-dom";
import paths from "../../paths";
import {isMobile} from "../../App";
import iconRight from "../../assets/feather/chevron-right.svg";
import {BettingTypesMap} from "../../store/bulletin.store";
import {GetStablemateIcon} from "../../pages/betting/components/betting-datatable";


let tjkBetslipTimer: any = null;

export function BetSlip(prop: any) {


    const forceUpdate = useForceUpdate();

    const navigate = useNavigate();

    useEffect(() => {
        let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
            forceUpdate();
            console.log(tjkBetSlipOptions);
        });
        return () => {
            betSlipActionSubject_subscription.unsubscribe();
            clearTimeout(tjkBetslipTimer);
        }
    }, []);

    let runsMap: any = {};
    let runsList: any = [];
    let horsesMap: any = {};

    runsList = tjkBetSlipOptions.hippodrome.runs.filter((run: any) => tjkBetSlipOptions.bettingSubType.label.split('-').includes(String(run.no)));
    for (let run of runsList) {
        runsMap[Number(run.no)] = run;
    }


    if (tjkBetSlipOptions.bettingSubType === null || tjkBetSlipOptions.hippodrome === null) {
        return <></>
    }


    let tabs: any = [];
    for (let i = Number(tjkBetSlipOptions.bettingSubType.id); i < Number(tjkBetSlipOptions.bettingSubType.id) + tjkBetSlipOptions.bettingType.column; i += 1) {
        tabs.push(i)
    }

    const runs = tjkBetSlipOptions.hippodrome.runs.filter((run: any) => tabs.includes(Number(run.no)));


    let emptyTabs: any = [];
    let maxHorseSize: number = 0;
    const generateEmptyTabs = () => {
        emptyTabs = [];
        for (let i = tabs.length + 1; i <= 7; i += 1) {
            emptyTabs.push(i)
        }
        maxHorseSize = runs.map((el: any) => el.horses.length).sort((a: number, b: number) => a - b).reverse()[0]
    }
    generateEmptyTabs();


    if (!tjkCompleteBettingTypes.includes(tjkBetSlipOptions.bettingType.id)) {
        tjkBetSlipOptions.complete = false;
    }

    let betCount = getBetCount()
    // console.log(tjkBetSlipOptions.betSlipMultiply);
    // console.log(betCount);
    // console.log(tjkBetSlipOptions.bettingType.price);
    let totalAmount = Number((Number(tjkBetSlipOptions.betSlipMultiply) * betCount * tjkBetSlipOptions.bettingType.price).toFixed(2));
    // console.log(totalAmount);
    let betSlipMaxMultiply = betCount > 0 ? Math.floor(tjkBetSlipOptions.bettingType.maxCouponAmount / betCount) : tjkBetSlipOptions.bettingType.maxCouponAmount;

    let multiplyChoices: any = [];
    if (betSlipMaxMultiply > 200) {
        multiplyChoices = _.range(1, 101);
        let mod = betSlipMaxMultiply % 50;
        multiplyChoices.push(..._.range(200, betSlipMaxMultiply, 50));
        if (mod > 0) {
            multiplyChoices.push((multiplyChoices[multiplyChoices.length - 1] + mod));
        }
    } else {
        multiplyChoices = _.range(1, betSlipMaxMultiply + 1);
    }

    if (totalAmount > tjkBetSlipOptions.bettingType.maxCouponAmount) {
        tjkBetSlipOptions.errorMessage = 'Toplam bahis tutarı ' + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + ' TL\'yi geçemez.';
        totalAmount = tjkBetSlipOptions.betSlipMultiply * betCount * tjkBetSlipOptions.bettingType.price;
    } else if (tjkBetSlipOptions.errorMessage === 'Toplam bahis tutarı ' + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + ' TL\'yi geçemez.') {
        tjkBetSlipOptions.errorMessage = null;
    }

    const playCoupon = (save: boolean = false) => {

        if (!auth.member || !auth.member.id) {
            modalServiceSubject.next({
                title: 'Üye Girişi',
                content: `<p>Bahis yapabilmeniz için önce oturum açmanız gerekli. Üye değilseniz hemen üye olabilir yada giriş yaparak bahis yapabilirsiniz.</p>`,
                confirm: {
                    sure: {
                        label: 'Giriş Yap',
                        class: 'btn btn-success',
                        action: () => {
                            navigate(paths.login.url)
                        }
                    },
                    cancel: {
                        label: 'Üye Ol',
                        class: 'btn btn-primary',
                        action: () => {
                            navigate(paths.register.url)
                        }
                    }
                }
            })
            return false;
        }

        tjkBetSlipOptions.inProgress = true;
        PlayTjkCoupon(save).then((result: any) => {
            if (result) {
                if (result.status === true && save === false) {
                    clearTjkBetSlip();

                    modalServiceSubject.next({
                        case: 'info',
                        title: 'Kuponunuz Oynandı',
                        // content: "Kuponunuz başarıyla oynandı. Kuponunuzun durumunu kuponlarım sayfasından takip edebilirsiniz."
                        content: `<p class="text-center"><b>${result.data.coupon_data.approve_code}</b> kodu ile <b>${CurrencyFormat(Number(result.data.coupon_data.amount || 0))} ₺</b> tutarındaki <b>${BettingTypesMap[Number(result.data.coupon_data.bet_type)]?.name}</b> kuponunuz başarıyla oynandı. <br/>Kuponunuzun durumunu kuponlarım sayfasından takip edebilirsiniz.</p>`,
                        confirm: {
                            sure: {
                                label: 'TJK Kuponlarım',
                                action: () => {
                                    navigate(paths.memberTickets.url);
                                }
                            },
                            cancel: {
                                label: 'Yeni Kupon Oyna',
                                action: () => {
                                    if (isMobile) {
                                        mobileBetSlipActions.isOpen = false;
                                        mobileBetSlipSubject.next('update')
                                    }
                                }
                            }
                        }
                    })

                } else if (result.status && save === true) {
                    modalServiceSubject.next({
                        case: 'info',
                        title: 'Kuponunuz Kaydedildi',
                        content: "Kuponunuz başarıyla Kaydedildi. Kuponunuzu kuponlarım sayfasında görüntüleyebilirsiniz",
                        confirm: {
                            sure: {
                                label: 'Kayıtlı Kuponlarım',
                                action: () => {
                                    navigate(paths.memberSavedTickets.url);
                                }
                            },
                            cancel: {
                                label: 'Yeni Kupon Oyna',
                                action: () => {
                                    if (isMobile) {
                                        mobileBetSlipActions.isOpen = false;
                                        mobileBetSlipSubject.next('update')
                                    }
                                }
                            }
                        }
                    })
                } else {
                    // tjkBetSlipOptions.errorMessage = typeof(result.message) === 'object' ? JSON.stringify(result.message): result.message;

                    let error_description: any = null;
                    let error_message: any = null;
                    if (result.data && result.data.error) {
                        error_description = result.data.error.error_description;
                        error_message = result.data.error.error_message;
                    }

                    let sure: any = null;
                    if (typeof (result.message) === 'string' && result.message.indexOf('Oturum') !== -1) {
                        sure = {
                            label: 'Giriş Yap',
                            action: () => {
                                navigate(paths.login.url);
                            }
                        }
                    }
                    if (error_message === 'TIMEOUT') {
                        sure = {
                            label: 'TJK Kuponlarım',
                            action: () => {
                                navigate(paths.memberTickets.url);
                            }
                        }
                    }

                    modalServiceSubject.next({
                        case: 'danger',
                        title: 'Hata',
                        content: error_description || (typeof (result.message) === 'object' ? JSON.stringify(result.message) : result.message),
                        confirm: {
                            sure: sure,
                            cancel: {
                                label: 'Tamam',
                            }
                        }
                    })
                    forceUpdate();
                }
            }
            tjkBetSlipOptions.inProgress = false;
            forceUpdate();
        });
        forceUpdate();
    }

    const confirmCoupon = () => {
        clearTimeout(tjkBetslipTimer);
        // console.log( tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price);
        if (auth.authentication && auth.member && auth.member.id) {
            if (betCount === 0) {
                tjkBetSlipOptions.errorMessage = 'Kuponunuz da oynanacak bir yarış bulunmamaktadır.';
            } else if ((tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) > 6500) {
                tjkBetSlipOptions.errorMessage = 'Kupon tutarı 6500 TL\'den fazla olamaz.';
            } else if (tjkBetSlipOptions.shareCoupon !== true && (tjkBetSlipOptions.usePointBalance ? Number(auth.member.balance_debit) + (auth.member.balance_point) : auth.member.balance_debit) < (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount)) {
                tjkBetSlipOptions.errorMessage = 'Bakiyeniz yetersiz.';
            } else if ((tjkBetSlipOptions.system ? (tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) : totalAmount) < tjkBetSlipOptions.bettingType.minCouponAmount) {
                tjkBetSlipOptions.errorMessage = 'Minumum bahis tutarı ' + CurrencyFormat(tjkBetSlipOptions.bettingType.minCouponAmount) + ' TL olmalıdır.';
            }
            if (tjkBetSlipOptions.errorMessage === null) {
                if (isMobile) {
                    playCoupon(false);
                } else {
                    let slipItem = tjkBetslipStore.filter((el: any) => {
                        if (
                            el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
                            el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
                            el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id)
                        ) {
                            return el;
                        }
                    })
                    let uniqueColumn = [...Array.from(new Set(slipItem.map((i: any) => i.column)))].sort();

                    let columnRunMap: any = {}
                    for (let col of uniqueColumn) {
                        columnRunMap[Number(col)] = slipItem.find((r: any) => r.column === col);
                    }

                    for (let run of runs) {
                        for (let horse of run.horses) {
                            if (!horsesMap[Number(run.no)]) {
                                horsesMap[Number(run.no)] = {}
                            }
                            horsesMap[Number(run.no)][Number(horse.no)] = horse;
                        }
                    }


                    modalServiceSubject.next({
                        title: `BAHİS DETAYI - ${tjkBetSlipOptions.hippodrome.location} - (${dateTimeStringFormat(tjkBetSlipOptions.hippodrome.date, true)})`,
                        width: '960px',
                        content: `
        <table class="table table-striped coupon-detail">
            <tbody>
            
                <tr>
                    <th class="text-left" style="width: 100px">Hipodrom</th>
                    <td class="text-left" style="width: calc(15% - 120px )">${tjkBetSlipOptions.hippodrome.location}</td>
                    <th class="text-left" style="width: 100px">Bahis Türü</th>
                    <td class="text-left" style="width: calc(25% - 120px )">${tjkBetSlipOptions.bettingType.name}</td>
                    ${!isMobile ? `<th className="text-left" style={{width: "100px"}}>Koşu</th>` : ''} 
                    ${!isMobile ? `<td className="text-left"
                                       style={{width: "calc(25% - 120px )"}}>${tjkBetSlipOptions.bettingSubType.label}</td>` : ''} 
                    
                </tr>
                
                <tr>
                    <th class="text-left" style="width: 100px">Bahis Sayısı</th>
                    <td class="text-left" style="width: calc(15% - 120px )">
                    
                    ${tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1] ?
                            tjkBetSlipOptions.systemMaxMin.join('-') : ''}
                    
                    ${tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ?
                            tjkBetSlipOptions.systemMaxMin[0] : ''}
                    
                    ${!tjkBetSlipOptions.system ? betCount : ''}
                    </td>
                    <th class="text-left" style="width: 100px">Misli</th>
                    <td class="text-left" style="width: calc(25% - 120px )">${tjkBetSlipOptions.betSlipMultiply}</td>
                    ${!isMobile ? `<th className="text-left" style={{width: "100px"}}>Tutar</th>` : ''}
                   ${!isMobile ? `<td className="text-left" style={{width: "calc(25% - 120px )"}}>
                    
                    ${tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1] ?
                            CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) + '₺ - ' + CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price) + '₺'
                            : (tjkBetSlipOptions.system ? CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) + '₺' : CurrencyFormat(totalAmount) + '₺')}
                    
                  
                                        
                    </td> ` : ''}
                </tr>
                ${isMobile ? ` <tr>
                      <th className="text-left" style={{width: "100px"}}>Koşu</th>
                      <td className="text-left"
                          style={{width: "calc(25% - 120px )"}}>${tjkBetSlipOptions.bettingSubType.label}</td>
                      <th className="text-left" style={{width: "100px"}}>Tutar</th>
                      <td className="text-left" style={{width: "calc(25% - 120px )"}}>

                          ${tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1] ?
                            CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) + '₺ - ' + CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price) + '₺'
                            : (tjkBetSlipOptions.system ? CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) + '₺' : CurrencyFormat(totalAmount) + '₺')}


                      </td>
                  </tr>` : ''}
                
            </tbody>
        </table>
        
        <div class="coupon-legs" style="width:auto; margin-top: 15px;">
          ${uniqueColumn.map((e: any) => {
                            return `<div class="col-12" style="width: ${isMobile ? '' : `calc(100% / ${uniqueColumn.length})`} ;flex: unset;max-width: unset;">
            <div class="run-no">${tjkBetSlipOptions.bettingType.columnLabel === 'Koşu' ? tjkBetSlipOptions.bettingSubType.id : e}. ${tjkBetSlipOptions.bettingType.columnLabel.toUpperCase()}</div>
            ${_.orderBy(slipItem, [(el: any) => {
                                if (el.horse === 's') {
                                    return 0
                                } else {
                                    return 1
                                }
                            }]).filter((h: any) => h.column === e).map((item: any, key: number) => {
                                return `<div class="horse-no d-flex">
                <div class="text-center ${horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no1 === 1 || horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no2 === 1 ? 'agf-horse border-0' : ''}" style=" font-weight: bold">${tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? (key + 1) + '.At' : (item.horse === 's' ? 'S' : horsesMap[columnRunMap[e].run][Number(item.horse)].no)}</div>
                <div class="text-left ps-2 ${horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no1 === 1 || horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no2 === 1 ? 'agf-horse border-0' : ''}" style="">
                  ${tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? item.horse : ''} ${item.horse === 's' ? 'HAZIR BAHİS' : horsesMap[columnRunMap[e].run][Number(item.horse)].name}
                </div>
              </div>`
                            }).join('')}
          </div>`
                        }).join('')}
        </div>
      `,
                        confirm: {
                            sure: {
                                class: 'btn-success',
                                label: 'Onayla',
                                action: () => {
                                    playCoupon(false);
                                }
                            },
                            cancel: {
                                label: 'Vazgeç',
                            }
                        }
                    })
                }


            }
            forceUpdate();

            tjkBetslipTimer = setTimeout(() => {
                tjkBetSlipOptions.errorMessage = null;
                forceUpdate();
            }, 5000);

        } else {
            navigate(`${paths.login.url}/${window.location.pathname.replaceAll('/', '|')}`);
        }

    }


    const showBettingTypeInfo = (bettingType: any) => {
        modalServiceSubject.next({
            case: 'info',
            title: bettingType.name,
            content: bettingType.info,
            confirm: {
                cancel: {
                    label: 'Tamam',
                    class: 'btn-warning fw-semibold w-100'
                }
            }
        })
    }

    const simpleView = (legs: any) => {
        let str: any = [];
        legs.forEach((l: any, i: number) => {
            if (i === 0) {
                str.push(l)
            } else if (Number(l) === Number(legs[i - 1]) + 1 && Number(l) === Number(legs[i + 1]) - 1) {
                if (str[str.length - 1] !== '-') {
                    str.push('-')
                }
            } else {
                str.push(l)
            }
        })
        return str.join(', ').replaceAll(', -, ', '-')
    }

    let betSlipLegs = getLegsSelections();


    const calculateProfit = () => {
        if (tjkBetSlipOptions.bettingType.id === 18) {
            var isFirstSixth = runsList[0].bets.filter((el: any) => el.code == '35').length > 0;
            var calculatedAgfRatio = 1;
            var distribution, prizes = 0;
            for (let i = 0; i < runsList.length; i++) {
                if (isFirstSixth) {
                    var bets = runsList[i].bets.filter((el: any) => el.code == '35');
                    console.log(bets);
                    if (bets && bets.length > 0){
                        distribution = bets[0].distribution;
                    }
                } else {
                    var bets = runsList[i].bets.filter((el: any) => el.code == '36');
                    if (bets && bets.length > 0){
                        distribution = bets[0].distribution;
                    }
                }
                prizes += runsList[i].prize;
                for (const horse of runsList[i].horses) {
                    let horseIsAdded: any = isAdded(i + 1, runsList[i], horse) !== false;
                    if (horseIsAdded) {
                        if (isFirstSixth) {
                            calculatedAgfRatio *= 100 / horse.agf_ratio1;
                        } else {
                            calculatedAgfRatio *= 100 / horse.agf_ratio2;
                        }
                        break;
                    }
                }
            }
            console.log(prizes)
            console.log(prizes/6)
            console.log(distribution)
            var distribution_rate = (distribution / (prizes /6)) / 100;
            console.log(distribution_rate);
            return (calculatedAgfRatio * tjkBetSlipOptions.bettingType.price * distribution_rate).toFixed(2);
        } else {
            return '';
        }
    }

    return (<>
        <div className="betslip">

            {isMobile && <button className="btn btn-primary btn-icon float-start py-1 px-1 me-2" onClick={() => {
                // setMobileBetslip(false)
                mobileBetSlipActions.isOpen = false;
                mobileBetSlipSubject.next('update')
            }}><img alt={"left"} src={iconLeft} className="invert-color me-1"/></button>}
            <h2 className="betting-type">{tjkBetSlipOptions.bettingType.name}

                {tjkBetSlipOptions.bettingType.info && <i className="info-circle float-end" onClick={() => {
                    showBettingTypeInfo(tjkBetSlipOptions.bettingType)
                }}>i</i>}

            </h2>
            <h3 className="hippodrome">{tjkBetSlipOptions.hippodrome.hippodrome}</h3>

            {!tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) && <div className="body betslip-body">
                {tabs.map((column: any, keyColumn: number) => {


                    let columnType = tjkBetSlipOptions.bettingSubType.label.split('-').length > 1 ? 'run' : 'horse';

                    let runObject = runsMap[columnType === 'run' ? Number(tjkBetSlipOptions.bettingSubType.label.split('-')[keyColumn]) : tjkBetSlipOptions.bettingSubType.id]
                    let horses = runObject.horses;
                    let isAddedAllStatus = isAddedAll(keyColumn + 1, runObject);

                    return (<ul key={`betslip-columns-${keyColumn}`}>

                        <li className="column-no" onClick={() => {
                            bulletinProvider.selectBettingColumn(keyColumn + 1);
                            betSlipActionSubject.next(true);
                        }}>{keyColumn + 1}{isMobile && <>. {tjkBetSlipOptions.bettingType.columnLabel}</>}</li>

                        {tjkBetSlipOptions.bettingSubType.label.split('-').length > 1 &&
                            <li className={`system ${isAdded(keyColumn + 1, runObject, "s") !== false ? 'horse-added' : ''}`}
                                onClick={() => {
                                    addToTJKBetSlip(
                                        keyColumn + 1,
                                        runObject,
                                        's',
                                        'toggle'
                                    )
                                }}>{isMobile ? 'Sistem' : 'S'}</li>}
                        {!isMobile && <li onClick={() => {
                            addAllToBetSlip(keyColumn + 1, runObject, isAddedAllStatus === false ? 'add' : 'remove');
                        }} className={`all ${isAddedAllStatus === true ? 'selected-all' : ''}`}>H</li>}

                        {isMobile && betSlipLegs[keyColumn].length === 0 &&
                            <li className="bg-dark-subtle"><i className="d-block text-center">Bu ayak için en az 1 at
                                seçmelisiniz!</i></li>}

                        {horses.map((horse: any, keyHorses: number) => {
                            let horseIsAdded: any = isAdded(keyColumn + 1, runObject, horse) !== false ? 'horse-added' : '';
                            if (isMobile && horseIsAdded !== 'horse-added') {
                                return <></>
                            }
                            return (<li key={`betslip-columns-horses-${keyHorses}`}
                                        className={`${!isMobile ? horseIsAdded : ''} ${horse.agf_sort_no1 === 1 || horse.agf_sort_no2 === 1 ? 'agf-horse' : ''}`}
                                        onClick={() => {
                                            if (!isMobile) {
                                                addToTJKBetSlip(
                                                    keyColumn + 1,
                                                    runObject,
                                                    horse,
                                                    'toggle'
                                                )
                                            }
                                        }}>{horse.run_status !== true ? <>
                                <img src={iconSlash} alt="/"/>
                            </> : <>{horse.no}{isMobile && <> - {horse.name} <GetStablemateIcon horse={horse}/> <i
                                className={"float-end"}>
                                <img alt={"sil"} src={iconTrash} width={14} onClick={() => {
                                    addToTJKBetSlip(
                                        keyColumn + 1,
                                        runObject,
                                        horse,
                                        'toggle'
                                    )
                                }} className="cursor-pointer float-right"/>
                            </i></>}</>}</li>)
                        })}

                        {maxHorseSize > horses.length && [...Array(maxHorseSize - horses.length)].map((horse: any, keyEmptyHorses: number) => {
                            return (<li className="emptyHorse" key={`betslip-columns-horses-${keyEmptyHorses}`}></li>)
                        })}


                    </ul>);

                })}

                {!isMobile && emptyTabs.map((emptyColumn: any, emptyColumnKey: number) => {
                    return (<ul className="emptyColumn" key={`betslip-emptyColumns-${emptyColumnKey}`}>
                        <li className="column-no">{emptyColumn}</li>
                        {tjkBetSlipOptions.bettingSubType.label.split('-').length > 1 && <li className="system">S</li>}
                        <li className="all">H</li>
                        {[...Array(maxHorseSize)].map((horse: any, keyEmptyHorses: number) => {
                            return (<li className="emptyHorse" key={`betslip-columns-horses-${keyEmptyHorses}`}></li>)
                        })}
                    </ul>);
                })}
            </div>}


            {tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) && <>
                <SortMyHorse parentUpdate={forceUpdate}></SortMyHorse>
            </>}


            <div className="footer">

                <div style={{border: 0, paddingTop: 0}}>
                    {tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? <div className="d-flex w-100">
                            {betSlipLegs?.map((l: any, k: number) => {
                                return l
                            }).filter((e: any) => e !== '').join(' / ')}
                        </div> :
                        <div className="d-flex w-100">
                            {betSlipLegs?.map((l: any, k: number) => {
                                return simpleView(l)
                            }).filter((e: any) => e !== '').join(' / ')}
                        </div>}
                </div>


                {tjkCompleteBettingTypes.includes(tjkBetSlipOptions.bettingType.id) && <div>
                    <label>Komple:</label>
                    <input type="checkbox" checked={tjkBetSlipOptions.complete} onChange={() => {
                        tjkBetSlipOptions.complete = !tjkBetSlipOptions.complete;
                        updateBetCount.status = true;
                        forceUpdate();
                    }}/>
                </div>}

                {tjkBetSlipOptions.system ? <div>
                    <label>Tutar:</label>
                    <input type="number" style={{textAlign: 'right'}} pattern="^[0-9\b]+$" min={1}
                           max={betSlipMaxMultiply}
                           step={1} value={tjkBetSlipOptions.maxAmount || ''}
                           onChange={e => setTjkBetSlipMaxAmount(e.target.value)}
                           onBlur={() => {
                               if (tjkBetSlipOptions.maxAmount === '') {
                                   setTjkBetSlipMaxAmount(1)
                               }
                           }}
                           onPaste={(e) => {
                               e.preventDefault()
                               return false;
                           }}/>
                </div> : <></>}

                {!tjkBetSlipOptions.system && <div>
                    <label>Misli:</label>
                    <div className={"betslip-misli"}>
                        <input type="number" className={"betslip-input"} style={{textAlign: 'left'}}
                               pattern="^[0-9\b]+$" min={1} max={betSlipMaxMultiply}
                               step={1} value={tjkBetSlipOptions.betSlipMultiply || ''}
                               onKeyDown={preventBetSlipValues}
                               onChange={e => setTjkBetSlipMultiply(e.target.value)}
                               onBlur={() => {
                                   if (tjkBetSlipOptions.betSlipMultiply === '') {
                                       setTjkBetSlipMultiply(1)
                                   }
                               }}

                               onPaste={(e) => {
                                   e.preventDefault()
                                   return false;
                               }}/>
                        <select className="betslip-select" value={tjkBetSlipOptions.betSlipMultiply}
                                onChange={e => setTjkBetSlipMultiply(Number(e.target.value))}>
                            {multiplyChoices.map((el: number, key: number) => {
                                return <option key={key} value={el}>{el}</option>
                            })}
                        </select>
                    </div>
                </div>}


                <div>
                    <label>Bahis:</label>
                    <span>
          {tjkBetSlipOptions.system ? <>
              {tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1] ? tjkBetSlipOptions.systemMaxMin.join('-') : tjkBetSlipOptions.systemMaxMin[0]}
          </> : <>
              {betCount}
          </>}
        </span>
                </div>

                <div>
                    <label>Tutar:</label>
                    <span>{tjkBetSlipOptions.system ? <>
                        {tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? <>
                            {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺
                        </> : <>
                            {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺
                            - {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price)}₺
                        </>}
                    </> : <>
                        {CurrencyFormat(totalAmount)}₺
                    </>}</span>
                </div>

            {/*    {tjkBetSlipOptions.bettingType.id === 18 && <div>
                    <label>Tahmini İkramiye:</label>
                    <span>{calculateProfit()} ₺</span>
                </div>}
*/}
                {auth.authentication && auth.member && auth.member.id ? <>
                    <div>
                        <label>Puan Kullan:</label>
                        <span>
                            <input type="checkbox" checked={tjkBetSlipOptions.usePointBalance}
                                   disabled={auth.member ? auth.member.balance_point <= 0 : true} onChange={() => {
                                setTjkUsePointBalance(!tjkBetSlipOptions.usePointBalance)
                            }}/> {auth.member ? auth.member.balance_point + '₺' : '0.00₺'}
                      </span>
                    </div>
                </> : <></>}

                {tjkBetSlipOptions.errorMessage ? <div className="mt-1 mb-1">
                    <div className="w-100 p-2 alert alert-danger m-0">
                        {tjkBetSlipOptions.errorMessage}
                    </div>
                </div> : <></>}


                {auth.authentication && auth.member && auth.member.is_editor === true && <>
                    <div>
                        <label>Paylaş:</label>
                        <span>
            <input type="checkbox" checked={tjkBetSlipOptions.shareCoupon} onChange={() => {
                setTjkShareCoupon(!tjkBetSlipOptions.shareCoupon)
            }}/>
          </span>
                    </div>
                </>}

                {tjkBetSlipOptions.inProgress ? <>
                    <div className="actions">
                        <button className="button-clear" disabled>
                            <img src={iconTrash} alt=""/>
                        </button>
                        <button disabled className="button-save">
                            <img src={iconSave} alt=""/>
                        </button>
                        <button className="button-play" disabled>Hemen Oyna</button>
                    </div>
                </> : <>
                    <div className="actions">
                        <button className="button-clear">
                            <img src={iconTrash} onClick={() => {
                                clearTjkBetSlip();
                            }} alt=""/>
                        </button>

                        <button onClick={() => {
                            playCoupon(true);
                        }} className="button-save">
                            <img src={iconSave} alt=""/>
                        </button>


                        {!tjkBetSlipOptions.shareCoupon && tjkBetSlipOptions.hippodrome.status && <>
                            <button
                                disabled={tjkBetSlipOptions.errorMessage || (tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] === 0 || betCount === 0) ? true : false}
                                className={`button-play`} onClick={() => {
                                confirmCoupon();
                            }}>
                                Hemen Oyna
                            </button>
                        </>}

                        {!tjkBetSlipOptions.hippodrome.status &&
                            <button className="button-play" disabled>Bahisler Açılmadı</button>}

                        {tjkBetSlipOptions.shareCoupon && <button
                            disabled={tjkBetSlipOptions.errorMessage || (tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] === 0 || betCount === 0) ? true : false}
                            className={`button-play share`} onClick={() => {
                            confirmCoupon();
                        }}>
                            Hemen Paylaş
                        </button>}


                    </div>
                </>}

            </div>
        </div>
    </>)
}


export function MobileBetslip(prop: any) {

    const selectBettingColumn = prop.selectBettingColumn;

    let betCount = getBetCount()
    let totalAmount = Number((Number(tjkBetSlipOptions.betSlipMultiply) * betCount * tjkBetSlipOptions.bettingType.price).toFixed(2));
    let betSlipMaxMultiply = betCount > 0 ? Math.floor(tjkBetSlipOptions.bettingType.maxCouponAmount / betCount) : tjkBetSlipOptions.bettingType.maxCouponAmount;


    if (totalAmount > tjkBetSlipOptions.bettingType.maxCouponAmount) {
        tjkBetSlipOptions.errorMessage = 'Toplam bahis tutarı ' + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + ' TL\'yi geçemez.';
        totalAmount = tjkBetSlipOptions.betSlipMultiply * betCount * tjkBetSlipOptions.bettingType.price;
    } else if (tjkBetSlipOptions.errorMessage === 'Toplam bahis tutarı ' + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + ' TL\'yi geçemez.') {
        tjkBetSlipOptions.errorMessage = null;
    }


    let betSlipLegs = getLegsSelections();

    const simpleView = (legs: any) => {
        let str: any = [];
        legs.forEach((l: any, i: number) => {
            if (i === 0) {
                str.push(l)
            } else if (Number(l) === Number(legs[i - 1]) + 1 && Number(l) === Number(legs[i + 1]) - 1) {
                if (str[str.length - 1] !== '-') {
                    str.push('-')
                }
            } else {
                str.push(l)
            }
        })
        return str.join(', ').replaceAll(', -, ', '-')
    }

    return (<div id="mobile-foot-betslip" className="mobile-foot-betslip sticky">
        <div className="row">
            <div className="col-3"><h2>BİLETİM</h2>
            </div>
            <div className="col-9 text-end">

                <button className="btn btn-primary float-end ms-3 fw-bold" onClick={() => {
                    mobileBetSlipActions.isOpen = true;
                    mobileBetSlipSubject.next('update')
                }}>
                    OYNA
                </button>
                BAHİS SAYISI: {tjkBetSlipOptions.system ? <>
                {tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1] ? tjkBetSlipOptions.systemMaxMin.join('-') : tjkBetSlipOptions.systemMaxMin[0]}
            </> : <>
                {betCount}
            </>}
                <span className="total d-block fw-bold">
          TUTAR: {tjkBetSlipOptions.system ? <>
                    {tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? <>
                        {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺
                    </> : <>
                        {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺
                        - {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price)}₺
                    </>}
                </> : <>
                    {CurrencyFormat(totalAmount)}₺
                </>}</span>
            </div>
        </div>

        <div className="row">
            <div className="d-flex py-2 pt-0 w-100 fw-bold fs-6">
                {betSlipLegs?.map((l: any, k: number) => {
                    return simpleView(l) === '' ? '-' : simpleView(l);
                }).join(' / ')}
            </div>
            {/*{betSlipLegs.map((l: any, k: number) => {*/}
            {/*    return (<div key={`keyMobileHorse-${k}`} onClick={() => {*/}
            {/*        selectBettingColumn(k + 1);*/}
            {/*    }} style={{width: `calc(100% / ${betSlipLegs.length})`}} className={`col ps-1 pe-1 py-2`}>*/}
            {/*        <span>{k + 1}.{tjkBetSlipOptions.bettingType.columnLabel}</span>*/}
            {/*        <span className="horses">{simpleView(l)}</span>*/}
            {/*    </div>)*/}
            {/*})}*/}
        </div>
    </div>)
}
