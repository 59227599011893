import "./statistics.css";

const iframeHtmlString = () => `
<html>
<head>
  <style>
    html,
    body {
      width: 100% !important;
      height: 100% !important;
      margin: 0;
      padding: 0;
    }
    .App {
      width: 100%;
      height: 100%;
      border: none;
      overflow: hidden;
    }
    #statisticsIFrame {
      width: 100%;
      height: 100%;
      border: none;
      overflow: hidden;
    }
    #iframeContainer {
      width: 100%;
      height: 100vh;
        overflow: hidden;
    }
  </style>
</head>
<body>
  <div id="iframeContainer">
    <iframe id="statisticsIFrame" src="https://tjk-statistics-web.bitrelic.io/" frameborder="0"></iframe>
  </div>
</body>
</html>
`;

export default function Statistics() {
  const htmlContent = iframeHtmlString();

  return <div className="iframeContainer-statistics" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}
